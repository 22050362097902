<template>
	<div>
		<vueDropzone 
			v-if="dropzone_loaded"
			ref="dropzone" 
			id="dropzone" 
			:options="dropzoneOptions"
			@vdropzone-success-multiple="onSuccess"
			@vdropzone-error="onError"
			@vdropzone-file-added="onAdded"
			:useCustomSlot=true
			class="mt-2"
		>
			<h5>{{ $t('monte.contract_document_dropzone') }} <font-awesome-icon :icon="['fal', 'download']"/></h5>
	    	<CustomTable
	    		ref="table"
	            id_table="contract_document"
	            :items="documents"
	            :busy.sync="table_busy"
	            primaryKey="contractdocument_id"
	            :hide_if_empty="true"
	            :externSlotColumns="['preview']"
	            :columsAddBegin="['preview']"
	            :preselected_items='preselected_items'
	        >
	            <template v-slot:[`custom-slot-cell(preview)`]="{ data }">
	                <a href="" class="" @click.prevent="quickPreview(data)">
	                    <font-awesome-icon :icon="['fas', 'eye']" />
	                </a>
	            </template>
	    	</CustomTable>
	    </vueDropzone>

        <div v-if="upload_mode" class="col-12 mt-3 text-center">
            <button @click="save" class="btn btn-primary">{{ $t('global.ajouter') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" spin /></button>
        </div>

        <b-modal size="xl" ref="modelPreview" hide-footer @hidden="preview = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="preview != null" :src="preview" height="1000px" width="100%" style="position:relative"></iframe>
        </b-modal>

        <ModalContractDocument ref="modal_document" :contract_id="contract_id" @submit="init_component"/>
        <ModalSendDocumentContract ref="modal_send_document" :contract_id="contract_id" @submit="init_component"/>
	</div>
</template>

<script type="text/javascript">
    import Contract from "@/mixins/Contract.js"
    import Navigation from "@/mixins/Navigation.js"
    import { EventBus } from "EventBus"
	import vue2Dropzone from 'vue2-dropzone'
	import 'vue2-dropzone/dist/vue2Dropzone.min.css'

	export default {
		name: 'ContractDocument',
		mixins: [Contract, Navigation],
		props: ['contract_id', 'type'],
		data () {
			return {
				documents: [],
				table_busy: false,
				preview: null,
				preselected_items: [],
				sended: false,
                events_tab: {
                    'TableAction::goToAddContractDocument': () => {
                        this.$refs.modal_document.openModal()
                    },
                    'TableAction::goToEditContractDocument': (doc) => {
                        this.$refs.modal_document.openModal(doc)
                    },
                    'TableAction::goToSendContractDocument': (doc) => {
						this.$refs.modal_send_document.openModal(doc)
                    },
                    'TableAction::goToSendContractDocuments': (doc) => {
						this.$refs.modal_send_document.openModalMultiple(doc)
                    },
                    'TableAction::goToDeleteContractDocument': this.deleteDocument,
                    'TableAction::goToDownloadContractDocument': this.download
                },
				dropzone_loaded: true,
				dropzoneOptions: {
					thumbnailWidth: 250,
					acceptedFiles: 'image/*,application/pdf',
					autoProcessQueue: false,
					uploadMultiple: true,
					clickable: false,
					dictDefaultMessage: this.getTrad('invoice.upload_invoice_supplier'),
					headers: {
						"Authorization" : "Bearer " + this.getConfig('token'),
						withCredentials: true
					},
					url: this.getUrlContractDocument(this.contract_id)
		      	},
		      	file_uploaded: 0,
		      	upload_mode: false,
		      	processing: false
			}
		},

		mounted() {
			this.init_component()
			this.$root.$on('bv::modal::hidden', () => {
				this.scrollTop()
			})
		},

		methods: {
			async init_component() {
				this.dropzone_loaded = true
				this.table_busy = true
				this.documents = await this.getDocumentByContract(this.contract_id)
				this.table_busy = false
				this.preselected_items = []
				if(this.type) {
					this.selectDocument()
				}
				this.dropzone_loaded = false
				this.$nextTick(()=> {
					this.dropzone_loaded = true
				})
			},
			download(doc) {
				const link = document.createElement("a");
				link.href = doc.contractdocument_s3_url;
				link.download = doc.contractdocument_filename;
				link.click();
			},
			async deleteDocument(doc) {
				await this.deleteContractDocument(this.contract_id, doc.contractdocument_id)
				this.init_component()
				this.$refs.table.refreshTable()
				EventBus.$emit('TableAction::stopSpin')
			},
            async quickPreview(doc) {
                this.$refs.modelPreview.show()
                this.preview = doc.contractdocument_s3_url
            },
            selectDocument() {
				if(this.type == 'AS' && !this.sended) {
					const as = this.documents.filter(doc => doc.contractdocument_as)
					if(as.length > 0) {
						this.preselected_items = [as[0]]
						this.$refs.modal_send_document.openModal(as[0])
						this.sended = true
					}
				}
				else if(this.type == 'DN' && !this.sended) {
					const dn = this.documents.filter(doc => doc.contractdocument_dn)
					if(dn.length > 0) {
						this.preselected_items = [dn[0]]
						this.$refs.modal_send_document.openModal(dn[0])
						this.sended = true
					}
				}
				else if(this.type == 'DN_AS' && !this.sended) {
					const dn = this.documents.filter(doc => doc.contractdocument_dn)
					const as = this.documents.filter(doc => doc.contractdocument_as)
					if(dn.length > 0 && as.length > 0) {
						this.preselected_items = [dn[0], as[0]]
						this.$refs.modal_send_document.openModalMultiple([dn[0], as[0]])
						this.sended = true
					}
				}
            },
			scrollTop() {
				this.$nextTick(()=> {
					window.scrollTo(0, 0);
				})
			},

			onSuccess(files) {
				this.file_uploaded += files.length
				if(this.$refs.dropzone.getAcceptedFiles().length != this.file_uploaded) {
					return false
				}

				this.processing = false
				this.successToast('toast.info_save_succes')
				this.dropzone_loaded = false
				this.upload_mode = false
				this.init_component()
			},

			onError() {
				this.processing = false
				this.failureToast("toast.info_save_failed")
			},

			async save() {
				if(this.processing) return false

				this.processing = true
				this.$refs.dropzone.processQueue()
			},

			onAdded() {
				this.upload_mode = true
				this.$nextTick(()=> {
					this.save()
				})
			}
		},

		watch: {
			type(val) {
				if(val) {
					this.selectDocument()
				}
			}
		},

		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            ModalContractDocument: () => import('@/components/Contract/ModalContractDocument'),
            ModalSendDocumentContract: () => import('@/components/Contract/ModalSendDocumentContract'),
			vueDropzone: vue2Dropzone
		}
	}

</script>